<template>
  <Layout class="login-page">
    <PageWrapper>
      <Card class="login-card" v-if="isEnabledAuth">
        <h3>{{ $t("loginPage.loginWith") }}</h3>
        <button class="btn-solid login-button google" @click="googleSignIn">
          <inline-svg
            :src="require('@/assets/icons/google.svg')"
            title="Google sign in"
            :width="30"
          ></inline-svg>
          <div class="vertical-separator"></div>
          Google
        </button>
        <button class="btn-solid login-button facebook" @click="facebookSignIn">
          <inline-svg
            :src="require('@/assets/icons/facebook.svg')"
            title="Facebook login"
            :width="30"
          ></inline-svg>
          <div class="vertical-separator"></div>
          Facebook
        </button>
        <router-link to="/terms">
          <div class="link-item">
            {{ $t("terms") }}
          </div>
        </router-link>
      </Card>
    </PageWrapper>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex'
import InlineSvg from 'vue-inline-svg'
import Card from '@/components/Card/Card'
import Layout from '@/components/Layout/Layout'
import PageWrapper from '@/components/PageWrapper/PageWrapper'

export default {
  computed: {
    ...mapGetters(['isEnabledAuth']),
  },
  methods: {
    googleSignIn() {
      this.$store.dispatch('loginWithGoogle')
    },
    facebookSignIn() {
      this.$store.dispatch('loginWithFacebook')
    },
  },
  watch: {
    isEnabledAuth(newValue) {
      if (!newValue) {
        this.$router.replace({ name: 'Home' })
      }
    },
  },
  mounted() {
    // Check if a login redirect was triggered before for Google or Facebook
    this.$store.dispatch('reviewRedirectResult')
  },
  components: {
    InlineSvg,
    Card,
    Layout,
    PageWrapper,
  },
}
</script>

<style lang="scss" scoped>
@import "@/css/base.scss";

.login-page {
  background-color: $whiteColor;
  ::v-deep .app-header .app-nav {
    justify-content: center;

    .main-logo {
      width: 200px;
    }
  }

  .login-card {
    width: 100%;
    max-width: 400px;
    margin: 2rem auto;
    display: flex;
    flex-direction: column;
    padding: 2rem;

    .login-button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $eighteen;
      color: $whiteColor;
      text-transform: none;
      padding: 0.7rem;
      margin-bottom: 0.8rem;

      svg {
        fill: $whiteColor;
      }

      .vertical-separator {
        margin: 0 0.4rem;
        width: 1px;
        height: 35px;
        background-color: $whiteColor;
      }

      &.facebook {
        $facebookColor: #3b5998;
        background-color: rgba($facebookColor, 0.9);

        &:hover {
          background-color: rgba($facebookColor, 1);
        }
        &:active {
          background-color: darken($facebookColor, 10%);
        }
      }

      &.google {
        $googleColor: #ea4335;
        background-color: rgba($googleColor, 0.9);

        &:hover {
          background-color: rgba($googleColor, 1);
        }
        &:active {
          background-color: darken($googleColor, 10%);
        }
      }
    }

    .link-item {
      color: $primaryColor;
      padding: 0.5rem 0;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media (max-width: $tabletWidth) {
    ::v-deep .app-header .app-nav {
      .main-logo {
        width: 155px;
      }
    }
  }
}
</style>
